<template>
  <div class="iframe_page" v-loading="loading">
    <CustomPageEdit :json="json" @submit="handleSubmit" />
  </div>
</template>

<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import request from '@/utils/request'
import { tryJSONParse } from '@/utils/utils'
import { ElMessage } from 'element-plus'
import CustomPageEdit from '@/components/CustomPageEdit/index.vue'

/**
 * options:
 * templateCode
 */

const router = useRouter()
const route = useRoute()
const templateCode = route.query?.templateCode || ''

const json = ref(null)
const loading = ref(false)

onMounted(() => {
  initInfo()
})

function initInfo() {
  getJson()
}
function getJson() {
  loading.value = true
  request({
    url: '/web/system/uiTemplateData/getUiTemplateDataByCode',
    data: { templateCode },
  })
    .then((data) => {
      const jsonObj = tryJSONParse(data?.templateConfig)
      console.log('🚀 ~ jsonObj:', jsonObj)
      json.value = {
        pageConfig: {
          title: '',
          isTabPage: '1',
          selectedColor: '#4b82f6',
          tabbarList: [
            {
              title: '首页',
              path: '/pages/hospital/index/index',
              icon: `https://dr.bld365.com/mpimages/tabbar/tabbar_home.png`,
              iconSelected: `https://dr.bld365.com/mpimages/tabbar/tabbar_home_active.png`,
              isActive: false,
            },
            {
              title: '科普',
              path: '/pages/science/index/index',
              icon: `https://dr.bld365.com/mpimages/tabbar/tabbar_zhidao.png`,
              iconSelected: `https://dr.bld365.com/mpimages/tabbar/tabbar_zhidao_active.png`,
              isActive: true,
            },
            {
              title: '问诊',
              path: '/pages/order/orderListChatting/index',
              icon: `https://dr.bld365.com/mpimages/tabbar/tabbar_chat.png`,
              iconSelected: `https://dr.bld365.com/mpimages/tabbar/tabbar_chat_active.png`,
              isActive: false,
            },
            {
              title: '我的',
              path: '/pages/wode/index/index',
              icon: `https://dr.bld365.com/mpimages/tabbar/tabbar_wode.png`,
              iconSelected: `https://dr.bld365.com/mpimages/tabbar/tabbar_wode_active.png`,
              isActive: false,
            },
          ],
        },
        ...jsonObj,
      }
    })
    .finally(() => (loading.value = false))
}

function handleSubmit(json) {
  // * @param templateCode     模板编码(非空)
  // * @param templateDataName 模板名称
  // * @param templateConfig   模板内容
  // * @param ifDefault        是否默认(0:否，1:是)
  // * @param remark           备注
  loading.value = true
  request({
    url: '/web/system/uiTemplateData/updateUiTemplateData',
    data: {
      templateCode,
      templateConfig: JSON.stringify(json || ''),
    },
  })
    .finally(() => (loading.value = false))
    .then(() => {
      ElMessage.success('编辑成功')
      router.back()
    })
}
</script>

<style lang="less" scoped>
.iframe_page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
</style>
