<template>
  <div class="iframe_box">
    <iframe class="iframe" id="iframe" :src="src" @load="isIframeLoad = true"></iframe>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, onUnmounted, toRefs } from 'vue'
import lodash from 'lodash'
import { getToken, getIsProd } from '@/utils/utils'

const emits = defineEmits(['submit'])
const props = defineProps(['json'])
const { json } = toRefs(props)

const isIframeLoad = ref(false)
const src = (() => {
  let url = `${'https://www.bld365.com/lowCodeH5/edit/index.html#/index'}?stamp=${new Date().getTime()}&projectKey=doctor&token=${getToken()}`
  if (!getIsProd()) {
    url = url + '&isTest=1'
  }
  return url
})()

onMounted(() => {
  listenMessage()
})
onUnmounted(() => {
  unListenMessage()
})

watch([isIframeLoad, json], ([isIframeLoadValue, jsonValue]) => {
  if (isIframeLoadValue && jsonValue) {
    // 给lowCodeH5传递json
    setTimeout(() => {
      document.getElementById('iframe')?.contentWindow.postMessage({ json: lodash.cloneDeep(jsonValue) }, '*')
    }, 800)
  }
})

function onPostMessage(event) {
  if (event?.data?.type === 'LOWCODEH5_SUBMIT_JSON' && event?.data?.json) {
    handleSubmit(event.data.json)
  }
}
function listenMessage() {
  window.addEventListener('message', onPostMessage, false)
}
function unListenMessage() {
  window.removeEventListener('message', onPostMessage)
}
function handleSubmit(json) {
  emits('submit', json)
}
</script>

<style lang="less" scoped>
.iframe_box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .iframe {
    border: none;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
}
</style>
